import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import START from "../../images/P3Quest.svg"

const Start = () => (
  <TutorialLayout
    imageSrc={START}
    step="2"
    title="Ai un loc de consum asociat?"
    buttonLink="/salut/locDeConsum"
    description="Pentru a deschide containerul cu telefonul mobil, trebuie să ai un loc de consum asociat."
    secondRadioText="Am deja un loc de consum"
    firstRadioText="Nu am un loc de consum"
    firstRadioLink="/salut/locDeConsum"
    secondRadioLink="/salut/finalizer"
  ></TutorialLayout>
)

export default Start
